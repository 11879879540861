/* eslint-disable no-console */
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { useWindowSize } from '../../utils/useWindowSize';
import { TerritoryTypes } from '../../utils/constants/enums';

export const IFRAME_BREAKPOINT = 1940;
export const QUERY_DETAILS_WIDTH = 350;
export const FRAME_MARGIN = 25;

// Would be better if we start using less  absolute/relative positions in containers that does not require those atribbutes
// Then after that is refactor this resize functionallity could be smoother
export const SCREEN_PADDING = 50;
export const MIN_IFRAME_HEIGHT = 500;
export const APROX_NAVS = 200;

const validateObjectValue = (value) => {
  if (value !== undefined && value !== null) {
    return true;
  }
  return false;
};

const validateFieldValue = (value) => {
  if (validateObjectValue(value) && value !== 0 && value !== '') {
    return true;
  }
  return false;
};

export const validateAPIResponse = (res) => {
  if (
    validateObjectValue(res) &&
    validateFieldValue(res.statusCode) &&
    validateObjectValue(res.data)
  ) {
    return true;
  }
  return false;
};

export const validateBaseUrl = (url) => {
  let baseUrl = '';
  if (url.indexOf('/trusted/#TICKET#/t/') !== -1) {
    baseUrl = url;
  } else if (url.indexOf('/t/') !== -1) {
    baseUrl = url.replace('/t/', '/trusted/#TICKET#/t/');
  } else if (url.indexOf('/#/') !== -1) {
    baseUrl = url.replace('/#/', '/trusted/#TICKET#/t/');
  }

  return baseUrl;
};

export const transformSelections = (objList, descriptor) => {
  const returnArray = [];
  if (descriptor === 'queryName') return objList;

  if (!objList) return returnArray;

  const objArray = Object.values(objList);

  if (objArray.length > 0 && typeof objArray[0] !== 'object' && objArray[0] !== null)
    return objList[descriptor];

  objArray.forEach((selection) => {
    returnArray.push(selection[descriptor]);
  });
  return returnArray;
};

export const getVariablesFromJSON = (filters, newQuery) => {
  const processingVariableOptions = [];
  // special case for territory selection
  const territoryTypeIds = [];
  const territoryTypesInNewQuery = get(newQuery, 'territorySelection.territoryTypes');
  if (territoryTypesInNewQuery !== undefined) {
    Object.keys(territoryTypesInNewQuery).forEach((key) => {
      territoryTypeIds.push(parseInt(key));
    });
  }
  filters.forEach(({ queryProperty, dataProperty, tableauKey, parameter }) => {
    let tempTableauKey = tableauKey;
    let dataPropertyTemp = dataProperty;
    if (
      territoryTypeIds.includes(TerritoryTypes.Dealer) &&
      queryProperty.includes('territorySelection')
    ) {
      tempTableauKey = `Dealer ${tableauKey}`;
      // adjustment for market dealer territory filters
      if (dataProperty === 'stateDescription') {
        dataPropertyTemp = 'stateAbbr';
      }
    }

    if (get(newQuery, queryProperty) !== undefined) {
      processingVariableOptions.push({
        queryKey: dataPropertyTemp,
        tableauKey: tempTableauKey,
        parameterValues: transformSelections(get(newQuery, queryProperty), dataPropertyTemp),
        parameter,
      });
    }
  });

  return processingVariableOptions;
};

export const getVariablesToBeApplied = (processingVariableOptions, initViz, trustedUrl) => {
  const maxURLSize = 1800;
  const initialVariableOptions = {};
  const pendingFiltersToApply = [];
  const pendingParametersToApply = [];
  // IMPORTANT //
  // Since the parameters just can be applied throw URL, the following will move all the parameters to the front
  const parametersAndFilters = sortBy(processingVariableOptions, ({ parameter }) => parameter);
  // eslint-disable-next-line no-restricted-syntax
  for (const [index, queryPart] of parametersAndFilters.entries()) {
    if (index >= 0 && queryPart?.parameterValues?.toString()?.length > 0) {
      if (
        JSON.stringify(initialVariableOptions).length +
          queryPart.tableauKey.length +
          JSON.stringify(queryPart.parameterValues.toString()).length <
        maxURLSize
      ) {
        if (Array.isArray(queryPart.parameterValues))
          queryPart.parameterValues = queryPart.parameterValues.map((x) => x.replace(/,/g, '\\,'));
        initialVariableOptions[queryPart.tableauKey] = queryPart.parameterValues;
      } else if (!queryPart.parameter) {
        pendingFiltersToApply.push(queryPart);
      } else {
        pendingParametersToApply.push(queryPart);
      }
    }
  }
  initViz(trustedUrl, initialVariableOptions);
  return { filters: pendingFiltersToApply, parameters: pendingParametersToApply };
};

const getFormatedHeight = (height) => {
  const offset = height - APROX_NAVS - FRAME_MARGIN * 2;
  return offset < MIN_IFRAME_HEIGHT ? MIN_IFRAME_HEIGHT : offset;
};
const getFormatedWidth = (width) => {
  const additionalWidth =
    SCREEN_PADDING * 2 + FRAME_MARGIN * 2 + (width < IFRAME_BREAKPOINT ? 0 : QUERY_DETAILS_WIDTH);
  return width - additionalWidth;
};

export function useFormatedWindowSize() {
  const [width, height] = useWindowSize();

  return [getFormatedWidth(width), getFormatedHeight(height)];
}

export const displayErrorToast = (severity, message, openToast) => {
  openToast({
    open: true,
    severity,
    message,
  });
};

export const defaultOptions = ({ OnReportDisplayed, hideToolbar = true, ...rest }) => ({
  hideToolbar,
  ...rest,
  onFirstInteractive: () => {
    OnReportDisplayed();
  },
});
