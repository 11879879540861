import React, { useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import OktaSignIn from '@okta/okta-signin-widget';
import { OKTA_SIGNIN_CONFIGURATION } from '../../utils/constants/constants';
import { useAuthentication } from '../../contexts/Authentication';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import './LoginForm.scss';

function LoginForm() {
  const widgetRef = useRef();
  const { oktaAuth } = useAuthentication();

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const { issuer, clientId, redirectUri, scopes } = OKTA_SIGNIN_CONFIGURATION;
    const widget = new OktaSignIn({
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      redirectUri,
      authParams: {
        issuer,
        scopes,
      },
    });

    widget.renderEl(
      { el: widgetRef.current },
      (res) => {
        localStorage.clear();
        oktaAuth.handleLoginRedirect(res.tokens);
      },
      (err) => {
        throw err;
      },
    );

    return () => widget.remove();
  }, [oktaAuth]);

  return (
    <>
      <img src="/images/ExperianLogo.svg" className="logo" alt="experianLogo" />
      <Typography variant="h2">Experian Automotive Product Access</Typography>
      <div spellCheck="false" className="oktaContainer" ref={widgetRef} />
    </>
  );
}
export default LoginForm;
