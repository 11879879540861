import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

function CallbackFromVelocityHub() {
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const idTokenParam = params.get('idToken');
    const accessTokenParam = params.get('access_token');

    if (idTokenParam && accessTokenParam) {
      try {
        const idTokenData = JSON.parse(decodeURIComponent(idTokenParam));
        const accessTokenData = JSON.parse(decodeURIComponent(accessTokenParam));

        const idToken = {
          idToken: idTokenData.idToken,
          claims: idTokenData.claims,
          expiresAt: idTokenData.expiresAt,
          scopes: idTokenData.scopes,
          authorizeUrl: idTokenData.authorizeUrl,
          issuer: idTokenData.issuer,
          clientId: idTokenData.clientId
        };

        const accessToken = {
          accessToken: accessTokenData.accessToken,
          claims: accessTokenData.claims,
          expiresAt: accessTokenData.expiresAt,
          tokenType: accessTokenData.tokenType,
          scopes: accessTokenData.scopes,
          authorizeUrl: accessTokenData.authorizeUrl,
          userinfoUrl: accessTokenData.userinfoUrl
        };

        if (idToken.idToken && accessToken.accessToken) {
          oktaAuth.tokenManager.add('idToken', idToken);
          oktaAuth.tokenManager.add('accessToken', accessToken);
          oktaAuth.authStateManager.updateAuthState();
          history.replace('/autocount'); // Redirect to the desired route after authentication
        } else {
          history.replace('/login'); // Redirect to login if tokens are not present
        }
      } catch (error) {
        history.replace('/login'); // Redirect to login if there's an error parsing the token
      }
    } else {
      history.replace('/login'); // Redirect to login if token parameter is not present
    }
  }, [oktaAuth, history]);

  return <div>Loading...</div>;
}

export { CallbackFromVelocityHub };