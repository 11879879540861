import React, { useEffect, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useIdleTimer } from 'react-idle-timer';
import { Redirect } from 'react-router-dom';

const AuthenticationContext = React.createContext();
AuthenticationContext.displayName = 'AuthenticationContext';

const actionTypes = {
  authenticate: 'authenticate',
  logout: 'logout',
};

const initialState = {
  firstName: 'Guillermo',
  email: 'email@domain.com',
  isAuthenticated: false,
};

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.authenticate: {
      return {
        ...state,
        isAuthenticated: true,
        firstName: action.payload.accessToken.claims.firstName,
        email:  action.payload.accessToken.claims.email,
      };
    }
    case actionTypes.logout: {
      return { ...initialState };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AuthenticationProvider({ children, ...props }) {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, dispatch] = React.useReducer(reducer, initialState);

  const { isIdle } = useIdleTimer({
    timeout: parseInt(process.env.REACT_APP_ACTIVE_TIMEOUT, 10),
  });


  useEffect(() => {
    const renewTokens = async () => {
      try {
        await oktaAuth.tokenManager.renew('idToken');
        await oktaAuth.tokenManager.renew('accessToken');
        oktaAuth.authStateManager.updateAuthState(); // Update Okta auth state

      } catch (error) {
        window.location.replace(`${window.location.origin}/login`);
      }
    };

    const checkAndRenewTokens = async () => {
      if (isIdle()) {
        oktaAuth.tokenManager.clear();
        window.location.replace(`${window.location.origin}/login`);
      } else {
        const tokens = await oktaAuth.tokenManager.getTokens();
        // Exit if no tokens are returned
        if (!tokens.idToken || !tokens.accessToken) {
          return;
        }
        const idTokenExpiresAt = tokens.idToken.expiresAt * 1000;
        const accessTokenExpiresAt = tokens.accessToken.expiresAt * 1000;
        const now = Date.now();

        // Renew tokens if they are about to expire within the next 5 minutes
        if (idTokenExpiresAt - now < 300000 || accessTokenExpiresAt - now < 300000) {
          await renewTokens();
        }
      }
    };

    const intervalId = setInterval(checkAndRenewTokens, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [oktaAuth, isIdle]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      dispatch({ type: actionTypes.authenticate, payload: authState });
    }
  }, [authState]);

  const logout = () => {
    localStorage.clear();
    oktaAuth.signOut();
  };

  return (
    <AuthenticationContext.Provider
      value={useMemo(
        () => ({ authState, oktaAuth, userInfo, logout }),
        [authState, oktaAuth, userInfo, logout],
      )}
      {...props}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

function useAuthentication() {
  const context = React.useContext(AuthenticationContext);
  if (!context) throw new Error(`useAuthentication must be used within the AuthenticationProvider`);

  return context;
}

function AuthHandler() {
  return <Redirect to="/welcome" />;
}

export { AuthenticationProvider, useAuthentication, reducer, AuthHandler, actionTypes };