import AdvancedQuery from '../../components/advancedQuery/AdvancedQuery';
import TableauManager from '../../components/tableauManager/TableauManager';

const LAYOUT = '/autocount/risk/';


const routes = {
  advancedQuery: {
    name: 'Criteria',
    component: AdvancedQuery,
    path: `${LAYOUT}advanced-query`,
  },
  reportDisplay: {
    name: 'Report Display',
    component: TableauManager,
    path: `${LAYOUT}report-display`,
    isHidden: true,
  },
};

export { routes, LAYOUT };
