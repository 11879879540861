/* eslint-disable no-console */
import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IFRAME_BREAKPOINT,
  useFormatedWindowSize,
  displayErrorToast,
  defaultOptions,
  getVariablesFromJSON,
  getVariablesToBeApplied,
  validateBaseUrl,
} from './TableauVizualization.utils';

import { marketFilters, riskFilters } from './QueryConfig';

import './TableauVizualization.scss';
import { REPORT_TYPES } from '../../utils/constants/constants';
import { useToast } from '../../contexts/Toast';
import { validateJsonAndGetTicket, removeEmptySpacesObject } from './queryValidation';
import { StoreContext } from '../../store/storeContext';

// Fix for AUT-4534, should be removed when the API is fixed
const DEALER_STATE_TEXT = '{"2":{"territoryTypeId":2,"territoryTypeNm":"Dealer State"}}';
const OWNER_STATE_TEXT = '{"1":{"territoryTypeId":1,"territoryTypeNm":"Owner State"}}';

function TableauVizualization({
  setIsLoading,
  refreshQuery,
  setQueryInfo,
  query,
  setVizReference,
  vizReference,
  reportTypeId,
}) {
  const { setToastState } = useToast();
  const ref = useRef(null);

  const [width, height] = useFormatedWindowSize();
  let viz;
  const history = useHistory();
  let pendingFiltersToApply = [];
  let pendingParametersToApply = [];
  const { actions } = useContext(StoreContext);
  

  const addMissingFilters = () => {
    let activeSheet = viz.getWorkbook().getActiveSheet();
    if (activeSheet.getSheetType() === 'dashboard') {
      const [firstWorksheet] = viz.getWorkbook().getActiveSheet().getWorksheets();
      activeSheet = firstWorksheet;
    }

    Promise.allSettled(
      pendingFiltersToApply.map((pendingVariableOption) =>
        activeSheet.applyFilterAsync(
          pendingVariableOption.tableauKey,
          pendingVariableOption.parameterValues,
          window.tableauV2.FilterUpdateType.REPLACE,
        ),
      ),
      pendingParametersToApply.map((pendingVariableOption) =>
        viz
          .getWorkbook()
          .changeParameterValueAsync(
            pendingVariableOption.tableauKey,
            pendingVariableOption.parameterValues,
          ),
      ),
    )
      .then(() => {setIsLoading(false)})
      .catch((err) => displayErrorToast('error', err.message, setToastState));
  };

  const initViz = (baseurl, initialVariableOptions) => {
    if (reportTypeId === REPORT_TYPES.marketReportTypeId) {
      viz = new window.tableauV2.Viz(ref.current, baseurl, {
        ...defaultOptions({
          OnReportDisplayed: addMissingFilters,
          hideTabs: true,
          height: '1000px',
        }),
        ...initialVariableOptions,
      });
    } else {
      viz = new window.tableauV2.Viz(ref.current, baseurl, {
        ...defaultOptions({
          OnReportDisplayed: addMissingFilters,
          height: '1400px',
        }),
        ...initialVariableOptions,
      });
    }
    setVizReference(viz);
  };

  const retrieveTicket = async () => {
    let response;
    try {
      response = await validateJsonAndGetTicket(reportTypeId, query, 'run');
    } catch (e) {
      actions.errorAction(e);
    }
    if (response?.userQueryOptionStatusTypeId !== 1) {
      setToastState({
        open: true,
        severity: 'error',
        status: response.userQueryOptionStatusTypeId,
        message: response.userQueryOptionStatusAdditionalDetails,
      });
      history.goBack();
    } else {
      const responseString = response.userQueryOptionJSON;
      const newQuery = JSON.parse(response.userQueryOptionJSON);

      // Fix for AUT-4534, should be removed when the API is fixed
      if (responseString.includes(DEALER_STATE_TEXT.substring(0, DEALER_STATE_TEXT.length - 2))) {
        newQuery.territorySelection.territoryTypes = JSON.parse(DEALER_STATE_TEXT);
      } else if (responseString.includes(OWNER_STATE_TEXT.substring(0, OWNER_STATE_TEXT.length - 2))) {
        newQuery.territorySelection.territoryTypes = JSON.parse(OWNER_STATE_TEXT);
      }



      const baseUrl = validateBaseUrl(Object.values(query.chosenReports)[0].visualizationView);

      const trustedUrl = baseUrl.replace('#TICKET#', response.ticket?.toString());
      newQuery.chosenReports = query.chosenReports;
      newQuery.queryName = query.queryName;
      const reportId = Object.keys(newQuery.chosenReports)[0];

      if (newQuery?.riskOptionsSelection?.riskDynamicFields?.[reportId]) {
        newQuery.riskOptionsSelection.riskDynamicFields =
          newQuery?.riskOptionsSelection?.riskDynamicFields?.[reportId];
      }

      if (newQuery?.riskOptionsSelection?.focusLender) {
        const clearedFocusLender = removeEmptySpacesObject(
          Object.values(newQuery?.riskOptionsSelection?.focusLender)[0],
        );
        newQuery.riskOptionsSelection.focusLender = {
          [clearedFocusLender.lenderId]: clearedFocusLender,
        };
      }

      setQueryInfo(newQuery);
      return [trustedUrl, newQuery];
    }
    return '';
  };

  useEffect(() => {
    if (ref?.current && vizReference) vizReference.setFrameSize();
  }, [width, height, vizReference]);

  useEffect(() => {
    setIsLoading(true);
    if (vizReference !== null) {
      vizReference.dispose();
    }

    if (!query) {
      history.goBack();
    } else if (JSON.stringify(query?.chosenReports) !== JSON.stringify({})) {
      retrieveTicket()
        .then((e) => {
          const [trustedUrl, newQuery] = e;
          if (newQuery !== undefined && Object.keys(newQuery).length > 0) {
            const config =
              reportTypeId === REPORT_TYPES.marketReportTypeId ? marketFilters : riskFilters();

            const processingVariableOptions = getVariablesFromJSON(config, newQuery);
            const { filters, parameters } = getVariablesToBeApplied(
              processingVariableOptions,
              initViz,
              trustedUrl,
            );
            pendingFiltersToApply = filters;
            pendingParametersToApply = parameters;
          }
        })
        .catch((error) => {
          actions.errorAction(error);
        });
    }
  }, [refreshQuery]);

  return <div ref={ref} />;
}

export { TableauVizualization, IFRAME_BREAKPOINT };
