import PropTypes from 'prop-types';
import React, { createContext, useReducer } from 'react';
import useActions from './actions';
import middleware from './middleware';
import { Reducer } from './reducer';

const StoreContext = createContext({});

const useCreateStore = (inputReducer, middlewares) => {
  const [state, dispatch] = useReducer(inputReducer, middlewares);

  const compose =
    (...funcs) =>
    (x) =>
      funcs.reduceRight((composed, f) => f(composed), x);

  const middlewareAPI = {
    getState: () => state,
    dispatch: (action) => dispatch(action),
  };

  const chain = middlewares.map((m) => m(middlewareAPI));
  const enhanceDispatch = compose(...chain)(dispatch);

  const actions = useActions(state, enhanceDispatch);
  return { state, dispatch: enhanceDispatch, actions };
};

function StoreProvider({ children }) {
  const store = useCreateStore(Reducer, [middleware]);

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { StoreContext, StoreProvider };
