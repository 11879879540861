const genericFilters = [
  {
    queryProperty: 'queryName',
    dataProperty: 'queryName',
    tableauKey: 'RptName',
    parameter: true,
  },
  {
    queryProperty: 'territorySelection.territoryType',
    dataProperty: 'territoryTypeId',
  },
  {
    queryProperty: 'territorySelection.dmas',
    dataProperty: 'acnDmaNm',
    tableauKey: 'DMA',
  },
  {
    queryProperty: 'territorySelection.counties',
    dataProperty: 'countyNm',
    tableauKey: 'County',
  },
  {
    queryProperty: 'territorySelection.zips',
    dataProperty: 'zipCd',
    tableauKey: 'ZIP Code',
  },
  {
    queryProperty: 'indicatorSelection.lenderTypes',
    dataProperty: 'lenderTypeDescription',
    tableauKey: 'Lender Type',
  },
  {
    queryProperty: 'indicatorSelection.dealerTypes',
    dataProperty: 'dealerTypeDescription',
    tableauKey: 'Dealer Type',
  },
  {
    queryProperty: 'indicatorSelection.transactionTypes',
    dataProperty: 'transactionTypeDescription',
    tableauKey: 'Finance Type',
  },
  {
    queryProperty: 'indicatorSelection.vehicleNewUsedTypes',
    dataProperty: 'vehicleNewUsedTypeDescription',
    tableauKey: 'New Used',
  },
  {
    queryProperty: 'dealersAndLendersSelection.dealers',
    dataProperty: 'dealerNm',
    tableauKey: 'Dealer Name',
  },
  {
    queryProperty: 'dealersAndLendersSelection.lenders',
    dataProperty: 'lenderNm',
    tableauKey: 'Lender Name',
  },
  {
    queryProperty: 'vehiclesSelection.manufacturers',
    dataProperty: 'manufacturerTx',
    tableauKey: 'Manufacturer',
  },
  {
    queryProperty: 'vehiclesSelection.makes',
    dataProperty: 'makeTx',
    tableauKey: 'Make',
  },
  {
    queryProperty: 'vehiclesSelection.models',
    dataProperty: 'modelTx',
    tableauKey: 'Model',
  },
  {
    queryProperty: 'vehiclesSelection.beginModelYear',
    dataProperty: 'modelYear',
    tableauKey: 'Begin Model Range',
  },
  {
    queryProperty: 'vehiclesSelection.endModelYear',
    dataProperty: 'modelYear',
    tableauKey: 'End Model Range',
  },
  {
    queryProperty: 'vehiclesSelection.subTypes',
    dataProperty: 'vehicleSubTypeTx',
    tableauKey: 'Experian Vehicle Sub Type',
  },
  {
    queryProperty: 'vehiclesSelection.classes',
    dataProperty: 'vehicleClassTx',
    tableauKey: 'Experian Vehicle Class',
  },
  {
    queryProperty: 'vehiclesSelection.classSizes',
    dataProperty: 'vehicleClassSizeTx',
    tableauKey: 'Experian Vehicle Class Size',
  },
  {
    queryProperty: 'vehiclesSelection.fuels',
    dataProperty: 'vehicleFuelTypeTx',
    tableauKey: 'Fuel Type Group',
  },
  {
    queryProperty: 'vehiclesSelection.types',
    dataProperty: 'vehicleTypeDescription',
    tableauKey: 'Experian Vehicle Type',
  },

  {
    queryProperty: 'reportingPeriods.beginDateRange',
    dataProperty: 'periodDate',
    tableauKey: 'Begin Date Range',
    parameter: true,
  },
  {
    queryProperty: 'reportingPeriods.endDateRange',
    dataProperty: 'periodDate',
    tableauKey: 'End Date Range',
    parameter: true,
  },
];

export const marketFilters = [
  ...genericFilters,
  {
    queryProperty: 'territorySelection.states',
    dataProperty: 'stateDescription',
    tableauKey: 'State',
  },
  {
    queryProperty: 'marketLayoutCriteriaSelection.marketDisplayBy',
    dataProperty: 'displayByDesc',
    tableauKey: 'Display by',
    parameter: true,
  },
  {
    queryProperty: 'marketLayoutCriteriaSelection.marketGroupBy',
    dataProperty: 'groupByDesc',
    tableauKey: 'Group by',
    parameter: true,
  },
];

export const riskFilters = () => {
  const filters = [
    ...genericFilters,
    {
      queryProperty: 'riskLayoutCriteriaSelection.riskDisplayBy',
      dataProperty: 'displayByDesc',
      tableauKey: 'Display by',
      parameter: true,
    },
    {
      queryProperty: 'riskLayoutCriteriaSelection.riskGroupBy',
      dataProperty: 'groupByDesc',
      tableauKey: 'Group by',
      parameter: true,
    },
    {
      queryProperty: 'territorySelection.states',
      dataProperty: 'stateAbbr',
      tableauKey: 'State',
    },
    {
      queryProperty: 'riskOptionsSelection.riskDynamicFields.Distribution',
      dataProperty: 'valueText',
      tableauKey: 'Distribution',
      parameter: true,
    },
    {
      queryProperty: 'riskOptionsSelection.riskDynamicFields.AttributeForAverage',
      dataProperty: 'valueText',
      tableauKey: 'Attribute for Average',
      parameter: true,
    },
    {
      queryProperty: 'riskOptionsSelection.riskDynamicFields.ShareCalculation',
      dataProperty: 'valueText',
      tableauKey: 'Share Calculation',
      parameter: true,
    },
    {
      queryProperty: 'riskOptionsSelection.editableRiskOption.scoreType',
      dataProperty: 'riskScoreTypeParameterValueNm',
      tableauKey: 'Select Score',
      parameter: true,
    },
    {
      queryProperty: 'riskOptionsSelection.editableRiskOption.scoreType',
      dataProperty: 'riskScoreTypeMinValue',
      tableauKey: 'Min. Score',
      parameter: true,
    },
    {
      queryProperty: 'riskOptionsSelection.editableRiskOption.scoreType',
      dataProperty: 'riskScoreTypeMaxValue',
      tableauKey: 'Max. Score',
      parameter: true,
    },
    {
      queryProperty: 'riskOptionsSelection.editableRiskOption.presetBandsDistributionValue',
      dataProperty: 'value',
      tableauKey: 'Score Distributions',
      parameter: true,
    },
    {
      queryProperty: 'riskOptionsSelection.editableRiskOption.tier1Low',
      dataProperty: 'value',
      tableauKey: 'Tier1Low',
      parameter: true,
    },
    {
      queryProperty: 'riskOptionsSelection.focusLender',
      dataProperty: 'lenderNm',
      tableauKey: 'Focus Lender',
      parameter: true,
    },
  ];
  let i = 1;
  while (i < 11) {
    filters.push({
      queryProperty: `riskOptionsSelection.editableRiskOption.tier${i}High`,
      dataProperty: 'value',
      tableauKey: `Tier${i}High`,
      parameter: true,
    });
    filters.push({
      queryProperty: `riskOptionsSelection.editableRiskOption.tier${i}Name`,
      dataProperty: 'value',
      tableauKey: `Tier${i}Name`,
      parameter: true,
    });

    // eslint-disable-next-line no-const-assign
    i++;
  }
  return filters;
};
